import { Button } from '@capturi/ui-components'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from '@chakra-ui/react'
import { css, keyframes } from '@emotion/react'
import React from 'react'

import CheckmarkAnimation from './CheckmarkAnimation'

export type ProcessingModalProps = {
  cancel: () => void
  onClose: () => void
  error: null | unknown
  isOpen: boolean
  isProgressFinished: boolean
  label: string
  processValue: number
  description?: string
  isCancelDisabled?: boolean
}

const animLineDown = keyframes`
0% {
  opacity: 0;
  transform: translateY(-15px);
}
20% {
  opacity: 0;
  transform: translateY(-15px);
}
100% {
  opacity: 1;
  transform: translateY(0%);
}
}
`

export const ProcessingModal: React.FC<ProcessingModalProps> = ({
  onClose,
  cancel,
  error,
  isOpen,
  label,
  description,
  isProgressFinished,
  processValue,
  isCancelDisabled,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />

      <ModalContent minH="330px">
        {error === null && isProgressFinished ? (
          <>
            <ModalBody maxH="330px" display="flex">
              <CheckmarkAnimation />
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader textAlign="center" pt="20">
              <Text fontFamily="heading" fontSize="4xl">
                {label}
              </Text>
            </ModalHeader>
            <ModalBody px="12">
              <Progress
                hasStripe
                size="md"
                value={processValue}
                max={100}
                min={0}
                maxW="80%"
                mx="auto"
                colorScheme="primaryBackground"
                bgColor="gray.200"
                borderRadius="sm"
                borderStyle="solid"
                borderWidth="1px"
                borderColor="gray.300"
                boxShadow="md"
                css={{
                  div: {
                    transition: 'all 250ms linear',
                  },
                }}
              />

              {description && (
                <Text
                  color="warning"
                  fontSize="lg"
                  textAlign="center"
                  p="8"
                  css={css`
                    animation: ${animLineDown} 1500ms ease-out forwards;
                  `}
                >
                  {description}
                </Text>
              )}
            </ModalBody>
          </>
        )}

        {!isProgressFinished && (
          <ModalFooter pt="0" pb="8">
            <Button
              variant="solid"
              size="md"
              secondary
              disabled={isCancelDisabled}
              mx="auto"
              onClick={() => {
                cancel()
                onClose()
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}
