import { Global, css } from '@emotion/react'

function CheckmarkAnimation(): JSX.Element {
  return (
    <>
      <Global
        styles={css`
          :root {
            --first-step-MdCheckmark: 1000ms;
          }
          @keyframes BackCircularLoader {
            0% {
              transform: scale(0.6) rotate(180deg);
              opacity: 0;
            }
            20% {
              transform: scale(0.6) rotate(180deg);
              opacity: 0;
            }
            60% {
              opacity: 1;
            }
            100% {
              transform: scale(0.9) rotate(00deg);
            }
          }
          #MdCheckmarkAnimaiton {
            animation: BackCircularLoader var(--first-step-MdCheckmark) forwards
              cubic-bezier(0.34, 1.56, 0.64, 1);
            transform-origin: center;
            transform-box: fill-box;
          }
        `}
      />
      <svg viewBox="0 0 574 379" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_ddd_342_1943)" id="MdCheckmarkAnimaiton">
          <path
            d="M287 105.75C240.77 105.75 203.25 143.27 203.25 189.5C203.25 235.73 240.77 273.25 287 273.25C333.23 273.25 370.75 235.73 370.75 189.5C370.75 143.27 333.23 105.75 287 105.75ZM270.25 231.375L228.375 189.5L240.184 177.691L270.25 207.674L333.816 144.107L345.625 156L270.25 231.375Z"
            fill="url(#paint0_linear_342_1943)"
          />
          <path
            d="M287 105.75C240.77 105.75 203.25 143.27 203.25 189.5C203.25 235.73 240.77 273.25 287 273.25C333.23 273.25 370.75 235.73 370.75 189.5C370.75 143.27 333.23 105.75 287 105.75ZM270.25 231.375L228.375 189.5L240.184 177.691L270.25 207.674L333.816 144.107L345.625 156L270.25 231.375Z"
            fill="url(#paint1_linear_342_1943)"
            fillOpacity="0.05"
          />
          <path
            d="M287 105.75C240.77 105.75 203.25 143.27 203.25 189.5C203.25 235.73 240.77 273.25 287 273.25C333.23 273.25 370.75 235.73 370.75 189.5C370.75 143.27 333.23 105.75 287 105.75ZM270.25 231.375L228.375 189.5L240.184 177.691L270.25 207.674L333.816 144.107L345.625 156L270.25 231.375Z"
            fill="url(#paint2_linear_342_1943)"
            fillOpacity="0.05"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddd_342_1943"
            x="-131.75"
            y="-103.625"
            width="837.5"
            height="837.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />

            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="8.375"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_342_1943"
            />
            <feOffset />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_342_1943"
            />

            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="30" />
            <feGaussianBlur stdDeviation="41.875" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            />

            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect3_dropShadow_342_1943"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_342_1943"
            x1="292.506"
            y1="105.75"
            x2="304.792"
            y2="272.344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#FAFAFA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_342_1943"
            x1="266.993"
            y1="100.491"
            x2="206.523"
            y2="231.665"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF4105" stopOpacity="0" />
            <stop offset="0.453125" stopColor="#FFDA79" stopOpacity="0.29" />
            <stop offset="0.64229" stopColor="#3AF3FF" stopOpacity="0.6" />
            <stop offset="1" stopColor="#38CFFF" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_342_1943"
            x1="244.892"
            y1="137.302"
            x2="261.88"
            y2="258.108"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4FF05" stopOpacity="0" />
            <stop offset="0.42484" stopColor="#FD63D2" stopOpacity="0.64" />
            <stop offset="1" stopColor="#38FFE7" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}
export default CheckmarkAnimation
